var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Аккаунты","filter":"","create":"","create-title":"Создать аккаунт","url-create":"/user/create","url-update":"/user/update/:id","store-module":"user","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Роль', value: 'role', width: 1, sortable: false },
        { text: 'Логин', value: 'login' },
        { text: 'ФИО', value: 'name' },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems,"check-edit-permission":function (item) {
        return item.super === false;
    },"check-delete-permission":function (item) {
        return item.super === false;
    }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ID","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  } ].concat( _vm.$store.state.user.roles
                  ),"label":"Роль"},model:{value:(filter.role),callback:function ($$v) {_vm.$set(filter, "role", $$v)},expression:"filter.role"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Логин","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.login),callback:function ($$v) {_vm.$set(filter, "login", $$v)},expression:"filter.login"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ФИО","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }