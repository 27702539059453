<template>
    <grid-view 
        title="Аккаунты"
        filter
        create
        create-title="Создать аккаунт"
        url-create="/user/create"
        url-update="/user/update/:id"
        store-module="user"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Роль', value: 'role', width: 1, sortable: false },
            { text: 'Логин', value: 'login' },
            { text: 'ФИО', value: 'name' },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
        :check-edit-permission="(item) => {
            return item.super === false;
        }"
        :check-delete-permission="(item) => {
            return item.super === false;
        }"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.active"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: false
                      },{
                        text: 'Да',
                        value: true
                      }]"
                      label="Активность"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.id"
                        clearable
                        autocomplete="new-email"
                        label="ID" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.role"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },
                      ...$store.state.user.roles
                      ]"
                      label="Роль"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.login"
                        clearable
                        autocomplete="new-email"
                        label="Логин" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.name"
                        clearable
                        autocomplete="new-email"
                        label="ФИО" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    data() {
        return {}
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Role
                const role_i = _.findIndex(this.$store.state.user.roles, {value: item.role});
                _.set(item, 'role', _.get(this, `$store.state.user.roles.${role_i}.text`, 'Неизвестно'));

                return item;
            })
        } 
    }
}
</script>